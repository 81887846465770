<template>

    <generic-section-hero v-bind="$attrs" class="SectionHomeHero">

        <template v-slot="{ slide, index, state, playVideo }">

            <div class="SectionHomeHero-ContentWrapper">

                <div class="SectionHomeHero-LogoPlay">

                    <img

                        v-if="slide.content.title_image"

                        class="SectionHomeHero-ContentImage"

                        :src="slide.content.title_image"

                    >

                    <span

                        v-if="slide.content.title_image"

                        class="SectionHomeHero-ContentButton"

                        @click="playVideo(index)"

                        :style="{ opacity: state.showVideo || state.loading ? 0: 1 }"

                    >

                        <svg><use :xlink:href="`#icon-play`"/></svg>

                    </span>

                </div>

                <span class="SectionHomeHero-ContentText">

                    <h3 v-if="slide.content.subtitle">

                        {{ slide.content.subtitle }}

                    </h3>

                    <p v-html="slide.content.description"></p>

                </span>

            </div>

        <!--
            <div
                class="SectionHomeHero-MenuButtons"
            >
                <div>
                    <button
                        class="btn-mb-rs"
                        @click='toggle = !toggle;'
                        outline
                        light
                    >Residencial
                    </button>
                    <transition name="component-fade" mode="out-in">
                        <div
                        class="toggle-shw"
                        v-show='toggle'>
                            <li class="li-info-1">
                                <img style="height:25px;margin-right:5px;" src="~assets/img/mex.png">
                                <span>México</span>
                            </li>
                            <li
                                v-for="(complex, index) in data.complexes.items"
                                :key="index"
                            >
                                <router-link :to="complex.path" class="li-info-3">
                                {{ complex.name }}
                                </router-link>
                            </li>
                            <li class="li-info-4">
                                <a class="a-info-1" target="_blank" href="https://begrand.es/">
                                <img style="height:25px;margin-right:5px;" src="~assets/img/spain.png">
                                <span>España</span>
                                </a>
                            </li>
                        </div>
                    </transition>
                </div>
                <flat-button
                    class="SectionHomeHero-Button"
                    href="https://oficinasdowntown.mx/"
                    el="a"
                    target="_blank"
                    outline
                    light
                >
                    Oficinas
                </flat-button>
                <flat-button
                    class="SectionHomeHero-Button"
                    href="https://rentas.begrand.mx/"
                    el="a"
                    target=""
                    outline
                    light
                >
                    Rentas
                </flat-button>
            </div>

        -->
        </template>

    </generic-section-hero>

</template>


<script>
import FlatButton from '@/app/components/buttons/FlatButton.vue'
import GenericSectionHero from '@/app/components/sections/GenericSectionHero.vue'

export default {
    name: 'SectionHomeHero',
    data () {
        return {
            toggle: false
        }
    },
    components: {
        FlatButton,
        GenericSectionHero,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        light: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        data() {
            return this.$store.state.app.data
        },
    },
}
</script>

<style>
.btn-mb-rs{
    border: 1px solid #fff;
    background: transparent;
    color: white;
    padding: 14px 25px 13px;
    width: 70vw;
    text-transform: uppercase;
    font-size: 12px;
    margin: 5px 0px;
}
.toggle-shw{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.li-info-1{
    color: white;
    padding: 5px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin: 10px;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
}

.li-info-2{
    margin:6px;
}
.li-info-3{
    color: white;
    padding: 5px;
    width: 50vw;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    text-transform: initial;
    text-decoration: none;
}
.li-info-4{
    color: white;
    padding: 5px;
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    text-transform: initial;
    text-decoration: none;
    border: 1px solid white;
    margin-bottom: 180px;
}
.a-info-1{
    color:white!important;
    text-decoration: none;
    font-size:13px;
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .6s ease-in-out;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

</style>

<style src="styles/components/sections/SectionHomeHero.styl" lang="stylus"></style>
